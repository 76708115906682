import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import {
  Field,
  reduxForm
  // formValueSelector
} from 'redux-form';
import classes from './LoginForm.module.scss';
import InputField from '../../StaticFormComponents/TextField';

import { Button, Form, Alert, Spinner } from 'reactstrap';

import { required } from 'redux-form-validators';

let LoginForm = (props) => {
  const { handleSubmit, pristine, submitting, error, twoFactor } = props;
  return (
    <Form className={classes.form} onSubmit={handleSubmit} autoComplete="off">
      <h4>Logowanie</h4>
      {error && <Alert color="danger">{error}</Alert>}

      {!twoFactor && (
        <React.Fragment>
          <Field
            name="username"
            label="Adres e-mail"
            component={InputField}
            placeholder="Login"
            type="text"
            autocomplete="off"
            className="form-control"
            validate={[required({ msg: 'Pole wymagane' })]}
          />
          <Field
            name="password"
            label="Hasło"
            component={InputField}
            type="password"
            autocomplete="off"
            className="form-control"
            validate={[required({ msg: 'Pole wymagane' })]}
          />
        </React.Fragment>
      )}

      {twoFactor && (
        <React.Fragment>
          <Alert color="info">
            Na twoją skrzynkę email wysłaliśmy jednorazowy PIN. Wprowadź go aby
            zalogować się do systemu.
          </Alert>
          <Field
            name="token"
            label="Jednorazowy PIN do logowania z wiadomości email"
            component={InputField}
            placeholder="PIN"
            type="text"
            autocomplete="off"
            className="form-control"
            validate={[required({ msg: 'Pole wymagane' })]}
          />
        </React.Fragment>
      )}
      <Button
        type="submit"
        color="success"
        className={classes.submitButton}
        disabled={submitting || pristine}>
        {!submitting && 'Zaloguj się'}
        {submitting && <Spinner size="sm" />}
      </Button>
      {twoFactor && (
        <Button
          color="danger"
          onClick={() => window.location.reload()}
          className={classes.cancelButton}>
          Anuluj
        </Button>
      )}
    </Form>
  );
};

LoginForm = reduxForm({
  form: 'login',
  destroyOnUnmount: true
})(LoginForm);

const mapStateToProps = (state) => {
  // const selector = formValueSelector('login');

  return {
    twoFactor: state.auth.twoFactor
    // username: selector(state, 'username'),
    // password: selector(state, 'passwords')
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (values) => {
      return new Promise((resolve, reject) => {
        dispatch(actions.auth({ values, resolve, reject }));
      });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
