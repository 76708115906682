import * as actionTypes from '../actions/actionTypes';
import { success } from 'redux-saga-requests';

const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties
  };
};

const initialState = {
  token: null,
  refreshToken: null,
  user: {
    auth_time: null,
    client_id: null,
    email: null,
    exp: null,
    iss: null,
    jti: null,
    locale: null,
    name: null,
    nbf: null,
    profile: null,
    programId: null,
    roleId: null,
    roleName: null,
    sub: null,
    username: null
  },
  error: null,
  loading: false,
  twoFactor: false,
  authRedirectPath: '/'
};

const authStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const authSuccess = (state, action) => {
  return updateObject(state, {
    token: action.token,
    refreshToken: action.refreshToken,
    user: {
      ...action.user,
      name: action.user.name
        ? action.user.name
        : state.user.name
        ? state.user.name
        : '',
      username: action.user.username
        ? action.user.username
        : state.user.username
        ? state.user.username
        : '',
      roleName: action.user.roleName
        ? action.user.roleName
        : state.user.roleName
        ? state.user.roleName
        : ''
    },
    error: null,
    loading: false,
    twoFactor: false
  });
};

const updateUserInfo = (state, action) => {
  return updateObject(state, {
    user: {
      ...state.user,
      name: action.data.name ? action.data.name : state.user.name,
      username: action.data.username
        ? action.data.username
        : state.user.username,
      roleName: action.data.roleName
        ? action.data.roleName
        : state.user.roleName
    }
  });
};

const authFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    twoFactor: false
  });
};
const authTwoFactor = (state, action) => {
  return updateObject(state, {
    twoFactor: action.value,
    loading: false
  });
};

const authLogout = (state, action) => {
  return updateObject(state, {
    token: null,
    user: initialState.user,
    refreshToken: null,
    twoFactor: false
  });
};

const setAuthRedirectPath = (state, action) => {
  return updateObject(state, { authRedirectPath: action.path });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_SUCCESS:
    case actionTypes.AUTH_RESTORE:
    case actionTypes.AUTH_REFRESH:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_2FA:
      return authTwoFactor(state, action);
    case success(actionTypes.FETCH_MENU):
      return updateUserInfo(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    case actionTypes.SET_AUTH_REDIRECT_PATH:
      return setAuthRedirectPath(state, action);
    case success(actionTypes.GET_ALLOWED_PROGRAMS):
      return {
        ...state,
        ...action.data
      };
    default:
      return state;
  }
};

export default reducer;
