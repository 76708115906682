// Authentication
export const AUTH_CHECK_STATE = 'AUTH_CHECK_STATE';
export const AUTH_USER = 'AUTH_USER';
export const AUTH_RESTORE = 'AUTH_RESTORE';
export const AUTH_REFRESH = 'AUTH_REFRESH';
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_2FA = 'AUTH_2FA';
export const AUTH_CHECK_TIMEOUT = 'AUTH_CHECK_TIMEOUT';
export const AUTH_INITIATE_LOGOUT = 'AUTH_INITIATE_LOGOUT';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_SAGA_RESTART = 'AUTH_SAGA_RESTART';
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';
export const AUTH_STORAGE_CHANGE = 'AUTH_STORAGE_CHANGE';
export const RECOVER_PASSWORD = 'RECOVER_PASSWORD';
export const RECOVER_PASSWORD_SET_NEW = 'RECOVER_PASSWORD_SET_NEW';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const RESEND_ACTIVATION = 'RESEND_ACTIVATION';

// Authentication - Programs
export const GET_ALLOWED_PROGRAMS = 'GET_ALLOWED_PROGRAMS';
export const SET_PROGRAM = 'SET_PROGRAM';

// Registration
export const REGISTER = 'REGISTER';

// Dynamic
export const FETCH_MENU = 'FETCH_MENU';
export const FETCH_DYNAMIC_PAGE = 'FETCH_DYNAMIC_PAGE';
export const CLEAR_DYNAMIC_SESSION = 'CLEAR_DYNAMIC_SESSION';

// Form engine
export const FORMENGINE_INITIALIZE = 'FORMENGINE_INITIALIZE';
export const FORMENGINE_CLIENT_CHANGE = 'FORMENGINE_CLIENT_CHANGE';
export const FORMENGINE_SERVER_CHANGE = 'FORMENGINE_SERVER_CHANGE';
export const FORMENGINE_SERVER_ARRAY_CHANGE = 'FORMENGINE_SERVER_ARRAY_CHANGE';
export const FORMENGINE_SERVER_FIELD_CHANGE = 'FORMENGINE_SERVER_FIELD_CHANGE';
export const FORMENGINE_SERVER_CHANGE_DATA_UPDATE =
  'FORMENGINE_SERVER_CHANGE_DATA_UPDATE';
export const FORMENGINE_CLIENT_EVENT = 'FORMENGINE_CLIENT_EVENT';
export const FORMENGINE_SERVER_EVENT = 'FORMENGINE_SERVER_EVENT';
export const FORMENGINE_SUBMIT_EVENT_SEND = 'FORMENGINE_SUBMIT_EVENT_SEND';
export const FORMENGINE_SUBMIT_EVENT_TRIGGER =
  'FORMENGINE_SUBMIT_EVENT_TRIGGER';
export const FORMENGINE_EVENT_TRIGGER = 'FORMENGINE_EVENT_TRIGGER';
export const FORMENGINE_FILE_UPLOAD_ACTION = 'FORMENGINE_FILE_UPLOAD_ACTION';
export const FORMENGINE_WORKFLOW_TRIGGER = 'FORMENGINE_WORKFLOW_TRIGGER';
export const FORMENGINE_WORKFLOW_TRIGGER_SEND =
  'FORMENGINE_WORKFLOW_TRIGGER_SEND';
export const FORMENGINE_WORKFLOW_RELOAD = 'FORMENGINE_WORKFLOW_RELOAD';

//File upload
export const FILE_UPLOAD_GET_UPLOAD_URL = 'FILE_UPLOAD_GET_UPLOAD_URL';

// Documents
export const DOCUMENTS_FETCH = 'DOCUMENTS_FETCH';
export const DOCUMENTS_DOWNLOAD = 'DOCUMENTS_DOWNLOAD';
export const DOCUMENTS_BY_OBJECT_ID_FETCH = 'DOCUMENTS_BY_OBJECT_ID_FETCH';
export const DOCUMENTS_PRINT_PDF = 'DOCUMENTS_PRINT_PDF';

// Table
export const TABLE_RECORDS_FETCH = 'TABLE_RECORDS_FETCH';

// Letters
// export const LETTER_TEMPLATES_FETCH = 'LETTER_TEMPLATES_FETCH';
export const LETTER_GENERATE_TEMPLATE = 'LETTER_GENERATE_TEMPLATE';
export const LETTER_SEND_BY_EMAIL = 'LETTER_SEND_BY_EMAIL';
export const LETTER_GENERATE_PDF = 'LETTER_GENERATE_PDF';

// Modals
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
