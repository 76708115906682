import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
// import { setAuthRedirectPath } from '../../store/actions';

import classes from './Login.module.scss';

import {
  Row,
  Col,
  Alert,
  Input,
  FormGroup,
  Label,
  // FormFeedback,
  Button
} from 'reactstrap';

class Activation extends React.Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(props.location.search);

    this.state = {
      token: '',
      email: '',
      emailPrefilled: false,
      twoFactor: false
    };

    if (query.get('email')) {
      this.state.email = query.get('email');
      this.state.emailPrefilled = true;
    }
    if (query.get('twoFactor')) {
      this.state.twoFactor = query.get('twoFactor');
    }
  }

  updateToken = (event) => {
    this.setState({
      token: event.target.value || ''
    });
  };
  updateEmail = (event) => {
    this.setState({
      email: event.target.value || ''
    });
  };
  render() {
    const props = this.props;

    // const query = new URLSearchParams(props.location.search);

    // if (query.get('email')) {
    //   return query.get('email');
    // }

    if (props.isAuthenticated) {
      return <Redirect to={props.authRedirectPath} />;
    } else {
      const { token, email, emailPrefilled, twoFactor } = this.state;
      const tokenIsEmpty = token === '' || !token;
      const emailIsEmpty = email === '' || !email;
      return (
        <React.Fragment>
          <Row>
            <Col xs="12" md="8" lg="6" xl="5" className={classes.centered}>
              <Row>
                <Col xs="12">
                  <div className={classes.form}>
                    <h4>Aktywacja Konta</h4>
                    {emailPrefilled && !twoFactor && (
                      <Alert color="success">
                        Rejestracja powiodła się. W ciągu kilku minut na adres
                        email <strong>{email}</strong> zostanie wysłana
                        wiadomość z jednorazowym numerem PIN. W celu aktywacji
                        konta należy wprowadzić go poniżej. Aktywacja konta jest
                        niezbędna do uzyskania dostępu do oferty
                        ubezpieczeniowej.
                      </Alert>
                    )}
                    {twoFactor === 'error' && (
                      <Alert color="danger">
                        Wprowadzono niepoprawny PIN. Prosimy o wpisanie
                        poprawnego numeru PIN wysłanego na email{' '}
                        <strong>{email}</strong>. Aktywacja konta jest niezbędna
                        do uzyskania dostępu do oferty ubezpieczeniowej.
                      </Alert>
                    )}
                    {twoFactor === 'expired' && (
                      <Alert color="danger">
                        <strong>Uwaga: twój PIN wygasł.</strong> W ciągu kilku
                        minut na adres email <strong>{email}</strong> zostanie
                        wysłana wiadomość z kolejnym jednorazowym numerem PIN. W
                        celu aktywacji konta należy wprowadzić go poniżej.
                        Aktywacja konta jest niezbędna do uzyskania dostępu do
                        oferty ubezpieczeniowej.
                      </Alert>
                    )}
                    {!emailPrefilled && (
                      <FormGroup>
                        <Label for="activation">Adres email:</Label>
                        <Input
                          placeholder="Adres email"
                          type="text"
                          className="form-control"
                          value={email}
                          onChange={this.updateEmail}
                          // rows={13}
                        />
                      </FormGroup>
                    )}
                    <FormGroup>
                      <Label for="activation">PIN z wiadomości email:</Label>
                      <Input
                        placeholder="PIN z wiadomości email"
                        type="number"
                        className="form-control"
                        value={token}
                        onChange={this.updateToken}
                        // rows={13}
                      />
                    </FormGroup>
                    {(tokenIsEmpty || emailIsEmpty) && (
                      <Button
                        color="success"
                        className={classes.submitButton}
                        disabled={true}>
                        Aktywuj konto
                      </Button>
                    )}
                    {!tokenIsEmpty && !emailIsEmpty && (
                      <a
                        className={`btn btn-success ${classes.submitButton}`}
                        role="button"
                        href={`/api/confirm-email?email=${encodeURIComponent(
                          email
                        )}&token=${token}`}>
                        Aktywuj konto
                      </a>
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </React.Fragment>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    authRedirectPath: state.auth.authRedirectPath
  };
};

// const mapDispatchToProps = (dispatch, props) => {
//   return {
//     setRedirect: () =>
//       dispatch(setAuthRedirectPath(props.location.state.referrer))
//   };
// };

export default connect(
  mapStateToProps
  // mapDispatchToProps
)(Activation);
