import * as actionTypes from './actionTypes';

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START
  };
};

export const authSuccess = (token, refreshToken, user) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token: token,
    refreshToken: refreshToken,
    user: user
  };
};

export const authRestore = (token, refreshToken, user) => {
  return {
    type: actionTypes.AUTH_RESTORE,
    token: token,
    refreshToken: refreshToken,
    user: user
  };
};

export const authRefresh = (token, refreshToken, user) => {
  return {
    type: actionTypes.AUTH_REFRESH,
    token: token,
    refreshToken: refreshToken,
    user: user
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error
  };
};

export const authTwoFactor = (value) => {
  return {
    type: actionTypes.AUTH_2FA,
    value: !!value
  };
};

export const logout = (reason) => {
  return {
    type: actionTypes.AUTH_INITIATE_LOGOUT,
    meta: { ...reason }
  };
};

export const authSagaRestart = (reason) => {
  return {
    type: actionTypes.AUTH_SAGA_RESTART,
    meta: { ...reason }
  };
};

export const logoutSucceed = () => {
  return {
    type: actionTypes.AUTH_LOGOUT
  };
};

export const checkAuthTimeout = (expirationTime) => {
  return {
    type: actionTypes.AUTH_CHECK_TIMEOUT,
    expirationTime: expirationTime
  };
};

export const auth = (payload) => {
  return {
    type: actionTypes.AUTH_USER,
    payload: payload
  };
};

export const setAuthRedirectPath = (path) => {
  return {
    type: actionTypes.SET_AUTH_REDIRECT_PATH,
    path: path
  };
};

export const authCheckState = () => {
  return {
    type: actionTypes.AUTH_STORAGE_CHANGE
  };
};

export const getAllowedPrograms = () => ({
  type: actionTypes.GET_ALLOWED_PROGRAMS,
  request: {
    url: `/identity/ProfilePermissions/AllowedPrograms`,
    method: 'get'
  },
  meta: {}
});

export const setProgram = (currentProgramId, newProgramId) => ({
  type: actionTypes.SET_PROGRAM,
  request: {
    url: `/identity/ChangeProgram/${newProgramId}`,
    method: 'post',
    data: {}
  },
  meta: { currentProgramId, newProgramId }
});

export const changePassword = ({ oldPassword, newPassword }) => ({
  type: actionTypes.CHANGE_PASSWORD,
  request: {
    url: '/identity/change-password',
    method: 'post',
    data: {
      oldPassword,
      newPassword
    }
  },
  meta: {
    asPromise: true
  }
});

export const passwordRecovery = ({ email, recaptchaToken }) => ({
  type: actionTypes.RECOVER_PASSWORD,
  request: {
    url: '/identity/PasswordReset/request-reset-password',
    method: 'post',
    data: {
      app: 'tvp',
      email,
      recaptchaToken,
      redirectTo: '/password-recovery?token={{token}}&email={{email}}'
    }
  },
  meta: {
    asPromise: true
  }
});

export const passwordRecoverySetNew = ({
  token,
  email,
  newPassword,
  recaptchaToken
  // app
}) => ({
  type: actionTypes.RECOVER_PASSWORD_SET_NEW,
  request: {
    url: '/identity/PasswordReset/set-new-password',
    method: 'post',
    data: { token, email, newPassword, recaptchaToken, app: 'tvp' }
  },
  meta: {
    asPromise: true
  }
});

export const resendActivation = ({ email }) => ({
  type: actionTypes.RESEND_ACTIVATION,
  request: {
    url: '/resend-confirm-email',
    method: 'post',
    data: {
      email,
      app: 'tvp'
    }
  },
  meta: {
    asPromise: true
  }
});
